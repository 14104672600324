import Typography from '@mui/material/Typography';
import {useSelector, shallowEqual} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {Box} from '@mui/material';
import '@app/screens/campaign-management/pages/setup-engagement-module/setup-engagement-screens/assign.css';
import Stack from '@mui/material/Stack';
import {fontFamilies} from '@app/themes/typography';
import theme from '@app/themes';
import {ApprovalChips} from './approvalChips';

const styleSheet = () => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    subHeader: {
      fontSize: '16px',
      fontFamily: fontFamilies.fontRegular,
      color: theme.colors.grey[200],
      margin: '6.7px auto auto 38px',
      fontWeight: 'normal',
    },
  };
};

export const ApprovalNavBar = () => {
  const styles = styleSheet();

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
    shallowEqual,
  );

  return (
    <Box sx={styles.container}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="h6" noWrap component="div">
          {navbarComponentHeading}
        </Typography>
      </Stack>
      <ApprovalChips />
    </Box>
  );
};
