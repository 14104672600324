import {createSelector} from '@reduxjs/toolkit';

export const routeSelector = {
  getIsRoutesLoading: () => {
    return createSelector(
      [(state: any) => state.routeState.isRoutesLoading],
      isRoutesLoading => isRoutesLoading,
    );
  },
  getAppRoutes: () => {
    return createSelector(
      [(state: any) => state.routeState.appRoutes],
      appRoutes => appRoutes,
    );
  },
  getRoutesRenderingError: () => {
    return createSelector(
      [(state: any) => state.routeState.routesRenderingError],
      routesRenderingError => routesRenderingError,
    );
  },
  getRoutesenderingErrorMessage: () => {
    return createSelector(
      [(state: any) => state.routeState.routesenderingErrorMessage],
      routesenderingErrorMessage => routesenderingErrorMessage,
    );
  },
  getSideBarOpen: () => {
    return createSelector(
      [(state: any) => state.routeState.sideBarOpen],
      sideBarOpen => sideBarOpen,
    );
  },
  getNavbarComponentName: () => {
    return createSelector(
      [(state: any) => state.routeState.navbarComponentName],
      navbarComponentName => navbarComponentName,
    );
  },
  getNavbarComponentHeading: () => {
    return createSelector(
      [(state: any) => state.routeState.navbarComponentHeading],
      navbarComponentHeading => navbarComponentHeading,
    );
  },
  getNavbarComponentSubHeading: () => {
    return createSelector(
      [(state: any) => state.routeState.navbarComponentSubHeading],
      navbarComponentSubHeading => navbarComponentSubHeading,
    );
  },
};
