import {
  applyFilterWatcher,
  applySetDefaultFilterWatcher,
} from '@app/components/right-sidebar/redux/filterHandlerSaga';
import { fetchRoutesDataWatcher } from '@app/router/redux/routeHandlerSaga';
import {
  accessGroupMenuItemsWatcher,
  addAccessGroupWatcher,
  disableAccessGroupWatcher,
  editAccessGroupWatcher,
  fetchAccessGroupMasterWatcher,
  fetchAccessGroupsAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/access-group-master/redux/saga';
import {
  editAdminUserWatcher,
  fetchAccessGroupDropDownWatcher,
  fetchAdminUsersDataWatcher,
  fetchDivisionDropdownWatcher,
  fetchStateDropdownWatcher,
  addAdminUserWatcher,
  disableAdminUserWatcher,
  fetchAdminUsersAutoSuggestDataWatcher,
  fetchAdminAddUsersAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/user-management/redux/saga';

import {
  fetchPlanAndMeetStatusListWatcher,
  updateMTPStatusWatcher,
  BulkGSPUpdateWatcher,
  bulkSTPUpdateWatcher,
  bulkMTPUpdateWatcher,
  bulkDCRPUpdateWatcher,
  fetchAllHierarchyWatcher
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import { all, call, spawn } from 'redux-saga/effects';
import {
  fetchCannotAccessModalUsersWatcher,
  fetchNotificationsWatcher,
  fetchUserInfoWatcher,
  markNotificationReadWatcher,
  fetchUserAppSettingsWatcher,
} from '@app/store/sagas/appSaga';
import {
  fetchHqsWatcher,
  fetchMTPRecommendationsWatcher,
  filterBasedDownloadExcelWatcher,
  fetchPreferredFiltersWatcher,
  fetchMTPUnlockReasonsWatcher,
  enableDCRWatcher,
  fetchPlanAndMeetHierarchyWatcher,
  HierarchyBasedDownloadExcelWatcher,
  webAccessUnlockWatcher,
  getWebAccessStatusWatcher,
  enableUnfilledDCRWatcher
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/saga';
import {
  fetchDcrUnlockRequestListWatcher,
  fetchDCRUnlockReasonsWatcher,
  approveDCRUnlockRequestWatcher,
  fetchDCRHqsWatcher,
  dcrDownloadExcelWatcher,
  dcrBulkUnlockWatcher
} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux';
import { fetchPartiesBySpidWatcher, 
  fetchPartyDetailBySpidWatcher, 
  fetchCloningPeriodWatcher, 
  transferPositionUpdateWatcher, 
  fetchPositionCloningListWatcher, 
  clonePositionUpdateWatcher, 
  fetchPositionTransferListWatcher,
  validatePartyTransferWatcher,
  fetchAreasBySpidWatcher,
  areaAllocationWatcher
} from '@app/screens/module-specific-utilities/pages/position-management/redux'
import {fetchApprovalWorkflowListWatcher, fetchMasterConfigDataWatcher, createApprovalResponseWatcher} from '@app/screens/configuration-management/pages/approval-workflow/redux';
import {updateMinApproverConfigWatcher} from '@app/screens/configuration-management/pages/approval-workflow/redux/saga';
import {modifyDCRRequestWatcher} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/saga';
import {unmappedActivityListWatcher,mappedActivityListWatcher,saveActivityMappingWatcher,fetchDivisionMappingWatcher,fetchActivitiesWatcher, saveActivitiesWatcher} from '@app/screens/configuration-management/pages/activity-mapping/redux'
import {
  fetch3x3SegmentationMrWatcher,
  fetchAssignDoctorsMrWatcher,
  fetchAssignManagersMrWatcher,
  fetchFocusBrandsWatcher,
  fetchLastYearHighlightsWatcher,
  fetchSetupEngagementManagerSelectionWatcher,
  fetchSetupEngagementModuleDivisionsWatcher,
  fetchSetupEngagementModulestateSelectionWatcher,
  fetchSetupEngagementModuleWatcher,
  fetchSetupEngagementResponsibilitiesWatcher,
  postEligibleDoctorsByConstraintsDataWatcher,
  removeCoverageSelectionDataWatcher,
  postSetupEngagementDataWatcher,
  inCompleteEngagementsDataWatcher,
  completedEngagementsDataWatcher,
  inProgressEngagementsDataWatcher,
  fetchBase64DataWatcher,
  inCompleteEngagementsConstraintsDataWatcher,
} from '@app/screens/campaign-management/pages/setup-engagement-module/redux/saga';
import {fetchApprovalCustomerListDataWatcher, fetchApprovalDataWatcher} from '@app/screens/campaign-management/pages/approval/redux/saga';
import {fetchGspScheduleListWatcher} from '@app/screens/configuration-management/pages/gsp-schedule/redux';
import {saveConfigurationWatcher, validatePrerequisitesWatcher} from '@app/screens/configuration-management/pages/gsp-schedule/redux/saga';
import {fetchDataCorrectionListWatcher,updateDataCorrectionWatcher} from '@app/screens/master-data-management/pages/data-correction/redux';

export function* rootSaga() {
  /**
   *
   *  Register watchers
   *
   */
  const sagas: any = [
    fetchRoutesDataWatcher,
    fetchUserInfoWatcher,
    fetchAdminUsersDataWatcher,
    fetchAccessGroupMasterWatcher,
    fetchAccessGroupDropDownWatcher,
    fetchDivisionDropdownWatcher,
    fetchStateDropdownWatcher,
    editAdminUserWatcher,
    addAdminUserWatcher,
    disableAdminUserWatcher,
    fetchAdminUsersAutoSuggestDataWatcher,
    fetchAdminAddUsersAutoSuggestDataWatcher,
    fetchCannotAccessModalUsersWatcher,
    applyFilterWatcher,
    applySetDefaultFilterWatcher,
    fetchAccessGroupsAutoSuggestDataWatcher,
    editAccessGroupWatcher,
    disableAccessGroupWatcher,
    addAccessGroupWatcher,
    accessGroupMenuItemsWatcher,
    fetchPlanAndMeetStatusListWatcher,
    updateMTPStatusWatcher,
    fetchNotificationsWatcher,
    fetchMTPRecommendationsWatcher,
    fetchHqsWatcher,
    filterBasedDownloadExcelWatcher,
    fetchPreferredFiltersWatcher,
    fetchMTPUnlockReasonsWatcher,
    markNotificationReadWatcher,
    enableDCRWatcher,
    fetchDcrUnlockRequestListWatcher,
    fetchDCRUnlockReasonsWatcher,
    approveDCRUnlockRequestWatcher,
    fetchUserAppSettingsWatcher,
    fetchPlanAndMeetHierarchyWatcher,
    HierarchyBasedDownloadExcelWatcher,
    fetchDCRHqsWatcher,
    dcrDownloadExcelWatcher,
    fetchPartiesBySpidWatcher,
    fetchAreasBySpidWatcher,
    fetchPartyDetailBySpidWatcher, 
    fetchCloningPeriodWatcher,
    transferPositionUpdateWatcher,
    fetchPositionCloningListWatcher,
    clonePositionUpdateWatcher,
    fetchApprovalWorkflowListWatcher,
    fetchMasterConfigDataWatcher,
    createApprovalResponseWatcher,
    BulkGSPUpdateWatcher,
    updateMinApproverConfigWatcher,
    fetch3x3SegmentationMrWatcher,
  	fetchAssignDoctorsMrWatcher,
  	fetchAssignManagersMrWatcher,
  	fetchFocusBrandsWatcher,
  	fetchLastYearHighlightsWatcher,
  	fetchSetupEngagementManagerSelectionWatcher,
  	fetchSetupEngagementModuleDivisionsWatcher,
  	fetchSetupEngagementModulestateSelectionWatcher,
  	fetchSetupEngagementModuleWatcher,
  	fetchSetupEngagementResponsibilitiesWatcher,
  	postEligibleDoctorsByConstraintsDataWatcher,
  	removeCoverageSelectionDataWatcher,
  	postSetupEngagementDataWatcher,
  	inCompleteEngagementsDataWatcher,
  	fetchApprovalDataWatcher,
    fetchApprovalCustomerListDataWatcher,
    modifyDCRRequestWatcher,
    webAccessUnlockWatcher,
    getWebAccessStatusWatcher,
    fetchPositionTransferListWatcher,
    validatePartyTransferWatcher,
    areaAllocationWatcher,
    unmappedActivityListWatcher,
    mappedActivityListWatcher,
    saveActivityMappingWatcher,
    fetchActivitiesWatcher,
    fetchDivisionMappingWatcher,
    saveActivitiesWatcher,
    enableUnfilledDCRWatcher,
    dcrBulkUnlockWatcher,
    bulkSTPUpdateWatcher,
    bulkMTPUpdateWatcher,
    bulkDCRPUpdateWatcher,
    fetchAllHierarchyWatcher,
    fetchGspScheduleListWatcher,
    validatePrerequisitesWatcher,
    saveConfigurationWatcher,
    fetchDataCorrectionListWatcher,
    updateDataCorrectionWatcher,
    completedEngagementsDataWatcher,
    inProgressEngagementsDataWatcher,
    fetchBase64DataWatcher,
    inCompleteEngagementsConstraintsDataWatcher
  ];

  /**
   * keep everything (e.g., child tasks) alive
   *
   **/
  yield all(
    sagas.map((saga: any) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log('Error ', e);
          }
        }
      }),
    ),
  );
}
