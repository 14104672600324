import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useStyles} from './styles';
import {Label} from '@app/components/elements/Label';
import Card from '@mui/material/Card';
import {BULK_UPDATE_ACTIONS} from '@app/common/constants';
import Colors from '@app/themes/colors';

export interface IProps {
  actionType: any;
  title?: string;
  handleChange?: any;
  handleDropdownSelected?: any;
  disable?: boolean;
  handleCardSelected?: any;
  selectedCard?: string;
  switchVal?: boolean;
  dropdownData?:any;
  dropdownValue?:any,
  handleRemarks?:any,
}

const status = ['Lock', 'Unlock'];
const dcrStatus = ['Disable','Enable']
const fields = {
  gsp: {
    switch: true,
    dropdown:false
  },
  web:{
    switch: true,
    dropdown:true
  },
  stp:{
    switch: false,
    dropdown:true,    
  },
  mtp:{
    switch: false,
    dropdown:true
  },
  reviewDcr:{
    switch: true,
    dropdown:false
  },
  enableDcr:{
    switch: false,
    dropdown:true
  }
}

const getInitialSwitchStatus = (actionType: string) => {
  return actionType === BULK_UPDATE_ACTIONS.WEB ? true : false;
}

function GenericAction(props: IProps) {
  const [statusSelected, setStatusSelected] = useState({
    checked: getInitialSwitchStatus(props?.actionType),
    status: props?.actionType === BULK_UPDATE_ACTIONS.REVIEW_DCR ? dcrStatus : status,
  });
  const classes = useStyles(statusSelected);
  const [dropdownValue, setDropdownValue] = useState<any>('');
  const [remarks, setRemarks] = useState<string>('');

  useEffect(() => {
    setStatusSelected({
      ...statusSelected,
      checked: props?.switchVal && props?.selectedCard === props?.actionType ? props?.switchVal : getInitialSwitchStatus(props?.actionType),
    });
  }, [props?.selectedCard]);

  useEffect(() => {
    if(props?.dropdownValue && !Object.keys(props?.dropdownValue)?.length){
      setDropdownValue('');
    }
  },[props?.dropdownValue])

  const handleStatus = (event: any) => {
    setStatusSelected({
      ...statusSelected,
      [event.target.name]: event.target.checked,
    });
    props?.handleChange(event.target.checked, props?.actionType);
  };

  const handleDropdownChange = (event: any) => {
    setDropdownValue(event.target.value);
    props?.handleDropdownSelected(event.target.value,props?.actionType);   
  };

  const handleCardClick = () => {
    if (props?.handleCardSelected) {
      props?.handleCardSelected(props?.actionType);
    }
  };

  const getCardStyles = () => {
    if (props.title) {
      return props?.selectedCard !== props?.actionType ? classes.small : '';
    } else {
      return classes.nonElevatedCard;
    }
  };

  const handleRemarks = (e:any) => {
    const val = e?.target?.value;
    const trimmedVal = val?.length > 2000 ? val?.substring(0, 2000) : val;
    setRemarks(trimmedVal);
    props?.handleRemarks(trimmedVal)
  }

  const getDropdownTitle = () => {
    if(props?.actionType === BULK_UPDATE_ACTIONS?.WEB) {
      return 'DURATION';
    } else if(props?.actionType === BULK_UPDATE_ACTIONS?.ENABLE_DCR) {
      return 'REASON';
    } else {
      return 'FROM:';
    }
  }

  const getDropdownPlaceholder = () => {
    if (props?.actionType === BULK_UPDATE_ACTIONS?.WEB) {
      return 'Select Duration';
    } else if (
      props?.actionType === BULK_UPDATE_ACTIONS?.STP ||
      props?.actionType === BULK_UPDATE_ACTIONS?.MTP
    ) {
      return 'Select Status';
    } else if(props?.actionType === BULK_UPDATE_ACTIONS?.ENABLE_DCR) {
      return 'Select Reason';
    }
  };

  return (
    <Card
      onClick={handleCardClick}
      className={getCardStyles()}
      data-testid={`${props?.actionType}-card`}>
      <Box
        className={`${
          props.title ? classes.boxWithTitle : classes.boxWithoutTitle
        }`}>
        {props.title && (
          <Label title={props.title} classes={classes.title} hideToolTip />
        )}
        <Box>
          {fields[props.actionType as keyof typeof fields]?.switch && 
          <FormControl fullWidth sx={{mb: 2}}>
            <Switch
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              checked={statusSelected.checked}
              onChange={handleStatus}
              name="checked"
              inputProps={{
                'aria-label': `secondary checkbox ${props?.actionType}`,
              }}
              data-testid={`statusSwitchOption${props?.actionType}`}
              disabled={props?.disable}
            />
          </FormControl>
          }

          {fields[props.actionType as keyof typeof fields]?.dropdown && (
            <>
            <FormControl fullWidth size='small' variant='outlined'>
              <InputLabel id={`duration-Dropdown-${props?.actionType}`}>
                {getDropdownPlaceholder()}
              </InputLabel>
              <Select
                labelId={`duration-Dropdown-${props?.actionType}`}
                id={`duration-Dropdown-${props?.actionType}`}
                className={classes.dropdown}
                data-testid={'duration-Dropdown'}
                defaultValue={dropdownValue}
                value={dropdownValue}
                label={getDropdownPlaceholder()}
                disabled={(props?.actionType === BULK_UPDATE_ACTIONS.WEB && !statusSelected?.checked) || props?.disable}
                onChange={handleDropdownChange}>
                {props?.dropdownData?.map((obj:any) => (
                  <MenuItem
                    key={obj.value}
                    value={obj.value}
                    data-testid={`dropdown_${obj.value}`}>
                    <ListItemText primary={obj.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {props?.actionType !== BULK_UPDATE_ACTIONS?.WEB && props?.actionType !== BULK_UPDATE_ACTIONS?.ENABLE_DCR && 
              <FormControl fullWidth className={classes.toTextContainer}>               
                <Label
                  title='To: '
                  hideToolTip
                  textColor={Colors.borderColor}
                  classes={classes.toText}
                />
                <Label
                  title={props?.actionType === BULK_UPDATE_ACTIONS?.STP ? "Submitted" : "Approved"}
                  hideToolTip
                />
              </FormControl>
            }
            {props?.actionType === BULK_UPDATE_ACTIONS?.ENABLE_DCR && 
              <>
                <Box marginTop={'15px'}>
                  <FormControl size="medium" fullWidth>
                    <TextField
                      size="small"
                      value={remarks}
                      onChange={e => handleRemarks(e)}
                      label="Remarks"
                      placeholder="Add Text"
                      style={{textAlign: 'left'}}
                      multiline
                      rows={1}
                    />
                    <Box textAlign="right">
                      <Label title={`${2000 - remarks?.length} characters left`} />
                    </Box>
                  </FormControl>
                </Box>
              </>}
            </>
          )}
        </Box>
      </Box>
    </Card>
  );
}

export default GenericAction;
