import {Box, Stack, Typography} from '@mui/material';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useStyles} from './styles';
import Colors from '@app/themes/colors';

export const showEngagementErrors = (errorMsg: any) => {
  return toast.error(errorMsg, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

export const successToast = (successMsg: any) => {
  return toast.success(successMsg, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

export const HardSoftLabel = () => {
  const classes: any = useStyles();
  return (
    <Stack direction={'row'} spacing={2} sx={{ml: 6, mb: 6}}>
      <Box sx={{display: 'flex', alignItems: 'center', mr: 4}}>
        <Box
          sx={{
            width: '16px',
            height: '16px',
            backgroundColor: `${Colors.orange[300]}`,
            mr: 1,
          }}></Box>
        <Typography className={classes.focusTitle}>Hard Constraint</Typography>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box
          sx={{
            width: '16px',
            height: '16px',
            backgroundColor: `${Colors.blue[100]}`,
            mr: 1,
          }}></Box>
        <Typography className={classes.focusTitle}>Soft Constraint</Typography>
      </Box>
    </Stack>
  );
};
