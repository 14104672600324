import {lazy} from 'react';
const Home = lazy(() =>
  import('@app/screens/home').then(module => ({default: module.Home})),
);
const Auth = lazy(() =>
  import('@app/screens/generic/Auth').then(module => ({default: module.Auth})),
);
const Login = lazy(() =>
  import('@app/screens/generic/Login').then(module => ({
    default: module.Login,
  })),
);
const AccessManagement = lazy(() =>
  import('@app/screens/access-management').then(module => ({
    default: module.AccessManagement,
  })),
);
const UserManagement = lazy(() =>
  import('@app/screens/access-management/pages/user-management').then(
    module => ({default: module.UserManagement}),
  ),
);
const AccessGroupMaster = lazy(() =>
  import('@app/screens/access-management/pages/access-group-master').then(
    module => ({default: module.AccessGroupMaster}),
  ),
);

const ModuleSpecifiUtilities = lazy(() =>
  import('@app/screens/module-specific-utilities').then(module => ({
    default: module.ModuleSpecifiUtilities,
  })),
);

const CampaignManagement = lazy(() =>
import('@app/screens/campaign-management').then(module => ({
  default: module.CampaignManagement,
})),
);

const SetUpEngagementModule = lazy(() =>
  import('@app/screens/campaign-management/pages/setup-engagement-module').then(
    module => ({
      default: module.EngagementModule,
    }),
  ),
);
const Approval = lazy(() =>
  import('@app/screens/campaign-management/pages/approval').then(module => ({
    default: module.Approval,
  })),
);

const PlanAndMeet = lazy(() =>
  import('@app/screens/module-specific-utilities/pages/plan-and-meet').then(
    module => ({default: module.PlanAndMeet}),
  ),
);
const DcrUnlockRequest = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/dcr-unlock-request'
  ).then(module => ({default: module.DcrUnlockRequest})),
);
const PositionManagement = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/position-management'
  ).then(module => ({default: module.PositionManagement})),
);
const ApprovalWorkflow = lazy(() =>
  import('@app/screens/configuration-management/pages/approval-workflow').then(
    module => ({default: module.ApprovalWorkflow}),
  ),
);
const ActivityMapping = lazy(() =>
  import('@app/screens/configuration-management/pages/activity-mapping').then(
    module => ({default: module.ActivityMapping}),
  ),
);
const GspSchedule = lazy(() =>
  import('@app/screens/configuration-management/pages/gsp-schedule').then(
    module => ({default: module.GspSchedule}),
  ),
);
const Unauthorized = lazy(() =>
  import('@app/screens/unauthorized').then(module => ({
    default: module.Unauthorized,
  })),
);
const ShowList = lazy(() =>
  import('@app/screens/campaign-management/pages/approval/view-list').then(
    module => ({
      default: module.ViewList,
    }),
  ),
);
const InProgress = lazy(() =>
  import('@app/screens/in-progress').then(module => ({
    default: module.InProgress,
  })),
);

const MasterDataManagement = lazy(() =>
  import('@app/screens/master-data-management').then(module => ({
    default: module.MasterDataManagement,
  })),
);

const DataCorrection = lazy(() =>
  import('@app/screens/master-data-management/pages/data-correction').then(
    module => ({default: module.DataCorrection}),
  ),
);

export const RoutesName: any = {
  Login: {
    path: '/login',
    componentName: 'Login',
  },
  Auth: {
    path: '/auth',
    componentName: 'Auth',
  },
  Home: {
    path: '/',
    componentName: 'Home',
  },
  ShowList: {
    path: '/approval/showlist/:engagementId',
    componentName: 'ShowList'
  },
  _Home: {
    path: '/home',
    componentName: 'Home',
  },
  Unauthorized: {
    path: '/unauthorized',
    componentName: 'Unauthorized',
  },
  InProgress: {
    path: '/in-progress',
    componentName: 'InProgress',
  },  
};

export const RoutesNameComponentMap: any = {
  _Home: <Home />,
  Home: <Home />,
  Login: <Login />,
  Auth: <Auth />,
  ShowList: <ShowList />,

  AccessManagement: <AccessManagement />,
  UserManagement: <UserManagement />,
  CampaignManagement: <CampaignManagement />,
  SetUpEngagementModule: <SetUpEngagementModule />,
  Approval: <Approval />,
  AccessGroupMaster: <AccessGroupMaster />,
  Unauthorized: <Unauthorized />,
  InProgress: <InProgress />,
  ModuleSpecifiUtilities: <ModuleSpecifiUtilities />,
  PlanAndMeet: <PlanAndMeet />,
  DCRUnlockRequest: <DcrUnlockRequest />,
  PositionManagement: <PositionManagement />,
  ApprovalWorkflow: <ApprovalWorkflow />,
  ActivityMapping: <ActivityMapping />,
  GspSchedule: <GspSchedule />,
  MasterDataManagement: <MasterDataManagement />,
  DataCorrection: <DataCorrection />
};
