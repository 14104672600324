import { createSelector } from '@reduxjs/toolkit';

export const approvalStateSelector = {
    getShowListData: () => {
        return createSelector(
          [(state: any) => state.approval.showListData],
          showListData => showListData,
        );
      }, 
      getLoaderData: () => {
        return createSelector(
          [(state: any) => state.approval.loaderData],
          loaderData => loaderData,
        );
      }, 
      getApprovalData: () => {
        return createSelector(
          [(state: any) => state.approval.approvalData],
          approvalData => approvalData,
        );
      }, 
      getApprovalStatusData: () => {
        return createSelector(
          [(state: any) => state.approval.approvalStatusData],
          approvalStatusData => approvalStatusData,
        );
      }, 
      getApprovalStatusResponseData: () => {
        return createSelector(
          [(state: any) => state.approval.approvalStatusResponseData],
          approvalStatusResponseData => approvalStatusResponseData,
        );
      }, 
      getApprovalCustomerListResponseData: () => {
        return createSelector(
          [(state: any) => state.approval.approvalCustomerListResponseData],
          approvalCustomerListResponseData => approvalCustomerListResponseData,
        );
      }, 
      getApprovalToggleState: () => {
        return createSelector(
          [(state: any) => state.approval.approvalToggleState],
          approvalToggleState => approvalToggleState,
        );
      }, 
      getApprovalwsfaCodes: () => {
        return createSelector(
          [(state: any) => state.approval.approvalwsfaCodes],
          approvalwsfaCodes => approvalwsfaCodes,
        );
      }, 
}