import {Provider} from 'react-redux';
import {getStore} from '@app/store';
import {AppRouter} from '@app/router/AppRouter';
import {createTheme, ThemeProvider, responsiveFontSizes} from '@mui/material/styles';
import {ErrorBoundary} from './ErrorBoundary';


export const store = getStore();

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    title: {main: string};
    white: {main: string};
  }
  interface PaletteOptions {
    title?: {main: string};
    white?: {main: string};
  }
}
declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    backgroundColors: {leftSidebar: string};
  }
  interface Theme {
    backgroundColors: {leftSidebar: string};
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: React.CSSProperties;
    error: React.CSSProperties;
    switch: React.CSSProperties;
    popupHeading: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    error?: React.CSSProperties;
    switch?: React.CSSProperties;
    popupHeading?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    error: true;
    switch: true;
    popupHeading: true;
  }
}

const palette = createTheme({
  backgroundColors: {
    leftSidebar: '#f5f8f9',
  },
  palette: {
    title: {main: '#322b7c'},
    white: {
      main: '#ffffff',
    },
    primary: {
      light: '#99322b7c',
      main: '#322b7c',
      dark: '#1c1939',
    },
    common: {
      white: '#ffffff',
      black: '#000000',
    },
    grey: {
      A400: '#3a1c1939',
      '100': '#acacac',
      '700': '#777777',
      '50': '#eeeeee',
    },
    success: {
      main: '#34b53a',
    },
    error: {
      main: '#f00',
    },
  },
});

export const themeOptions:any = createTheme(palette, {
  typography: {
    fontFamily: ['Poppins !important'].join(','),
    fontSize: '0.928571rem !important',
    title: {
      fontWeight: '600',
      fontSize: 18,
      fontStyle: 'normal',
    },
    subtitle1: {
      fontWeight: '600',
      textTransform: 'uppercase',
      fontSize: 12.7,
      color: palette.palette.primary.dark,
    },
    error: {
      color: palette.palette.error.main,
      fontSize: 12,
      fontWeight: '500',
    },
    switch: {
      fontSize: 20,
      color: palette.palette.primary.main,
      fontWeight: '500',
    },
    popupHeading: {
      fontSize: 22.7,
      color: palette.palette.common.black,
      fontWeight: '500',
    },
    leftSideBar: {
      color: '#534e91',
    },
    rightSideBar: {
      color: '#534e91',
    },
    
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 800,
          color: '#322b7c',
          fontFamily: ['Poppins !important'].join(','),
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          fontWeight: '600',
          fontStyle: 'normal',
          letterSpacing: 0,
          textAlign: 'center',
          fontSize: 12.7,
        },
      },
    },
  },
  
  backgroundColors: {
    leftSidebar: '#f5f8f9',
  },
  palette: {
    primary: {
      main: '#322b7c',
    },
    title: {main: '#322b7c'},
    white: {
      main: '#ffffff',
    },

  },
 
});
export const DialogProps = { 
  sx: {
 marginLeft:'22px !important'
  },

};

const theme = createTheme(themeOptions);
function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
