import {API_PATH} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put, select} from '@redux-saga/core/effects';
import {setupEngagementModuleStateSelector} from './selectors';
import {
  completedEngagementsDataCreatorTypeName,
  fetch3x3SegmentationDataCreatorTypeName,
  fetchBase64DataCreatorTypeName,
  fetchFocusBrandsCreatorTypeName,
  fetchLastYearHighlightsDataCreatorTypeName,
  fetchSetupEngagementManagerSelectionCreatorTypeName,
  fetchSetupEngagementModuleCreatorTypeName,
  fetchSetupEngagementModuleDivisionsCreatorTypeName,
  fetchSetupEngagementModuleManagerMrDataCreatorTypeName,
  fetchSetupEngagementModuleMrDataCreatorTypeName,
  fetchSetupEngagementModuleStateSelectionCreatorTypeName,
  fetchSetupEngagementResponsibilitiesCreatorTypeName,
  inCompleteEngagementsConstraintsDataCreator,
  inCompleteEngagementsConstraintsDataCreatorTypeName,
  inCompleteEngagementsDataCreatorTypeName,
  inProgressEngagementsDataCreatorTypeName,
  postEligibleDoctorsByConstraintsDataCreatorTypeName,
  postSetupEngagementDataCreator,
  postSetupEngagementDataCreatorTypeName,
  removeCoverageSelectionDataCreatorTypeName,
  setupEngagementModuleStateActions,
} from './slice';
import {showEngagementErrors} from '../setup-engagement-screens/engagementConfig';
import {appSelector} from '@app/store/selectors';

/**
 * fetch engagementData Watcher
 */
export function* fetchSetupEngagementModuleWatcher() {
  yield takeLatest(
    fetchSetupEngagementModuleCreatorTypeName,
    fetchSetupEngagementModuleWatcherWorker,
  );
}

/**
 * fetch engagementData Worker
 */
export function* fetchSetupEngagementModuleWatcherWorker(action: any): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.url !== 'undefined'
    ) {
      if (action.payload.url === '') {
        const url = `${API_PATH.setupEngagement.engagementData}`;
        const engagementData = yield call(NetworkService.get, url, {}, {});
        if (engagementData.status === 200) {
          yield put(
          setupEngagementModuleStateActions.setEngagementData(
            engagementData?.data,
          ),
        );
        } else {
          showEngagementErrors(
          engagementData?.data?.error || 'Failed to load data',
        );
      }
    }

    else if (
      action.payload.url === 'reasons' &&
      typeof action.payload.reasonType !== undefined
      ) {
      const url = `${API_PATH.setupEngagement.engagementData}/${action.payload.url}?reasonType=${action.payload.reasonType}`;
      const dropdownData = yield call(NetworkService.get, url, {}, {});
      if (dropdownData.status === 200) {
        yield put(
        setupEngagementModuleStateActions.setRejectionDropdownData(
          dropdownData?.data,
        ),
      );
      } else {
        showEngagementErrors(
        dropdownData?.data?.error || 'Failed to load data',
      );
    }
  }

  }
  } catch (error) {
    console.error(error);
  }
}

/**
 * fetch focusBrands Watcher
 */
export function* fetchFocusBrandsWatcher() {
  yield takeLatest(
    fetchFocusBrandsCreatorTypeName,
    fetchFocusBrandsWatcherWorker,
  );
}

/**
 * fetch focusBrands Worker
 */
export function* fetchFocusBrandsWatcherWorker(action: any): any {
  try {
   
    const inCompleteEngagementInfo = yield select(
      setupEngagementModuleStateSelector.getInCompleteEngagementInfo()
    )
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.divisionId !== 'undefined' &&
      typeof action.payload.divisionName !== 'undefined'
    ) {
      const url = `${API_PATH.setupEngagement.focusBrands}?division=${action.payload.divisionId}`;
      const focusBrands = yield call(NetworkService.get, url, {}, {});
      let brands = [];
      let sortedBrands: any = [];
      let divisionId = action.payload.divisionId;
      let divisionName = action.payload.divisionName;

      if (focusBrands.status === 200) {
        yield put(
          setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
        );
      } else {
        yield put(
          setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
        );
        showEngagementErrors(focusBrands?.data?.error || 'failed to load data');
      }

      if (focusBrands.data.length > 0) {
        brands = focusBrands.data.map((i: any) => {
          return {
            ...i,
            divisionId: divisionId,
            divisionName: divisionName,
          };
        });
      }

      sortedBrands = [...brands];
      //filtering duplicate values and sorting
      const sortedFocusBrands = [
        ...new Map(sortedBrands.map((i: any) => [i.id, i])).values(),
      ].sort((a: any, b: any) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      );

      //incomplete engagement focus brands update
      let finalBrands = [...sortedFocusBrands]
      let focusBrandsData:any = []
      if(inCompleteEngagementInfo?.engagementfocusbrands &&
        inCompleteEngagementInfo?.engagementfocusbrands.length>0
      ) {
        let labels = inCompleteEngagementInfo?.engagementfocusbrands.map((i: any) => i.focusBrandName)
        let data: any = []
        finalBrands.forEach((item:any) => {
          if (labels.includes(item.name)) {
            data.push(item)
          }
        })
        finalBrands.forEach((item: any) => {
          if (!(labels.includes(item.name))) {
            data.push(item)
          }
        })
        focusBrandsData.push(...data)
      }
      else {
       focusBrandsData.push(...finalBrands)
      }

      yield put(
        setupEngagementModuleStateActions.setFocusBrandsData(focusBrandsData)
      );
    }
  } catch (error) {
    yield put(
      setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
    );
    console.error(error);
  }
}

/**
 * fetch divisionsData Watcher(coverage and constraints)
 */
export function* fetchSetupEngagementModuleDivisionsWatcher() {
  yield takeLatest(
    fetchSetupEngagementModuleDivisionsCreatorTypeName,
    fetchSetupEngagementModuleDivisionsWatcherWorker,
  );
}

/**
 * fetch divisionsData Worker(coverage and constraints)
 */
export function* fetchSetupEngagementModuleDivisionsWatcherWorker(
  action: any,
): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.divisions !== 'undefined'
    ) {
      const url = `${API_PATH.setupEngagement.engagementData}/bydivisions?divisions=${action.payload.divisions}`;
      const divisionsData = yield call(NetworkService.get, url, {}, {});
      if (divisionsData.status === 200) {
        yield put(
          setupEngagementModuleStateActions.setDivisionData(
            divisionsData?.data,
          ),
        );
      } else {
        showEngagementErrors(
          divisionsData?.data?.error || 'Failed to load data',
        );
      }
    } else {
      throw new Error();
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * fetch responsibilities Watcher
 */
export function* fetchSetupEngagementResponsibilitiesWatcher() {
  yield takeLatest(
    fetchSetupEngagementResponsibilitiesCreatorTypeName,
    fetchSetupEngagementResponsibilitiesWatcherWorker,
  );
}

/**
 * fetch responsibilities Worker
 */
export function* fetchSetupEngagementResponsibilitiesWatcherWorker(
  action: any,
): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.engagementTypeId !== 'undefined'
    ) {
      const url = `${API_PATH.setupEngagement.responsibilities}?engagementTypeId=${action.payload.engagementTypeId}`;
      const responsibilities = yield call(NetworkService.get, url, {}, {});
      if (responsibilities.status === 200) {
        yield put(
          setupEngagementModuleStateActions.setResponsibilitiesData(
            responsibilities?.data,
          ),
        );
      } else {
        showEngagementErrors(
          responsibilities?.data?.error || 'Failed to load data',
        );
      }
    } else {
      throw new Error();
    }
  } catch (error) {
    console.error(error);
  }
}


/**
 * base64String Watcher
 */
export function* fetchBase64DataWatcher() {
  yield takeLatest(
    fetchBase64DataCreatorTypeName,
    fetchBase64DataWatcherWorker,
  );
}

/**
 * base64String Worker
 */
export function* fetchBase64DataWatcherWorker(
  action: any,
): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.engagementId !== 'undefined'
    ) {
      const url = `${API_PATH.setupEngagement.previewDocuments}?engagementId=${action.payload.engagementId}`;
      const uploadedFiles = yield call(NetworkService.get, url, {}, {});

      if (uploadedFiles.status === 200 && uploadedFiles?.data) {
        yield put(
          setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
        );
        yield put(
          setupEngagementModuleStateActions.setBase64Data(
            uploadedFiles?.data,
          ),
        );
      } else {
        yield put(
          setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
        );
        showEngagementErrors(
          uploadedFiles?.data?.error || 'Failed to load data',
        );
      }
    } else {
      throw new Error();
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * fetch No of Mrs Watcher
 */
export function* fetchAssignDoctorsMrWatcher() {
  yield takeLatest(
    fetchSetupEngagementModuleMrDataCreatorTypeName,
    fetchAssignDoctorsMrWatcherWorker,
  );
}

/**
 * fetch No of Mrs Worker
 */
export function* fetchAssignDoctorsMrWatcherWorker(action: any): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.geoTypeId !== 'undefined' &&
      typeof action.payload.countryId !== 'undefined' &&
      typeof action.payload.selections !== 'undefined' &&
      typeof action.payload.divisions !== 'undefined' &&
      typeof action.payload.engagementId !== 'undefined'
    ) {
      const url = `${API_PATH.setupEngagement.doctorsMrData}?geoTypeId=${
        action.payload.geoTypeId
      }&countryId=${action.payload.countryId}&selections=${action.payload.selections}&divisions=${
        action.payload.divisions
      }&engagementId=${action.payload.engagementId}`;
      const assignDoctorsMrData = yield call(NetworkService.get, url, {}, {});
      if (assignDoctorsMrData.status === 200) {
        yield put(
          setupEngagementModuleStateActions.setAssignDoctorsMrData(
            assignDoctorsMrData?.data,
          ),
        );
      } else {
        showEngagementErrors(
          assignDoctorsMrData?.data?.error || 'Failed to load data',
        );
      }
    } else {
      throw new Error();
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * fetch 3x3_Segmentation Watcher
 */
export function* fetch3x3SegmentationMrWatcher() {
  yield takeLatest(
    fetch3x3SegmentationDataCreatorTypeName,
    fetch3x3SegmentationMrWatcherWorker,
  );
}

/**
 * fetch 3x3_Segmentation Worker
 */
export function* fetch3x3SegmentationMrWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.setupEngagement.segmentationData}?engagement3X3SegmentationType=0&zones=0`;
    const segmentationData = yield call(NetworkService.get, url, {}, {});

    if (segmentationData.status === 200) {
      yield put(
        setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
      );
      yield put(
        setupEngagementModuleStateActions.setSegmentationData(
          segmentationData?.data,
        ),
      );
    } else {
      showEngagementErrors(segmentationData?.data?.error);
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * fetch No of Manager Mrs Watcher
 */
export function* fetchAssignManagersMrWatcher() {
  yield takeLatest(
    fetchSetupEngagementModuleManagerMrDataCreatorTypeName,
    fetchAssignManagersMrWatcherWorker,
  );
}

/**
 * fetch No of Manager Mrs Worker
 */
export function* fetchAssignManagersMrWatcherWorker(action: any): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.selections !== 'undefined' &&
      typeof action.payload.divisions !== 'undefined' &&
      typeof action.payload.roleId !== 'undefined' &&
      typeof action.payload.engagementId !== 'undefined' 
    ) {
      const url = `${API_PATH.setupEngagement.managersMrData}?selections=${action.payload.selections}&divisions=${action.payload.divisions}&roleId=${action.payload.roleId}&engagementId=${action.payload.engagementId}`;
      const assignManagersMrData = yield call(NetworkService.get, url, {}, {});
      if (assignManagersMrData.status === 200) {
        yield put(
          setupEngagementModuleStateActions.setAssignManagersMrData(
            assignManagersMrData?.data,
          ),
        );
      } else {
        showEngagementErrors(
          assignManagersMrData?.data?.error || 'Failed to load data',
        );
      }
    } else {
      throw new Error();
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * fetch stateSelectionData Watcher
 */
export function* fetchSetupEngagementModulestateSelectionWatcher() {
  yield takeLatest(
    fetchSetupEngagementModuleStateSelectionCreatorTypeName,
    fetchSetupEngagementModuleWatcherStateSelectionWorker,
  );
}

/**
 * fetch stateSelectionData Worker
 */
export function* fetchSetupEngagementModuleWatcherStateSelectionWorker(
  action: any,
): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.geoTypeId !== 'undefined' &&
      typeof action.payload.countryId !== 'undefined'
    ) {
      const url = `${API_PATH.setupEngagement.stateSelection}/${action.payload.geoTypeId}/${action.payload.countryId}`;
      const stateSelectionData = yield call(NetworkService.get, url, {}, {});
      if (stateSelectionData.status === 200) {
        yield put(setupEngagementModuleStateActions.setLoaderData({isLoading: false}))
        yield put(
          setupEngagementModuleStateActions.setStateSelectionData(
            stateSelectionData?.data,
          ),
        );
      } else {
        yield put(setupEngagementModuleStateActions.setLoaderData({isLoading: false}))
        showEngagementErrors(
          stateSelectionData?.data?.error || 'Failed to load data',
        );
      }
    } else {
      throw new Error();
    }
  } catch (error) {
    setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
    console.error(error);
  }
}

/**
 * fetch managerSelectionData Watcher
 */
export function* fetchSetupEngagementManagerSelectionWatcher() {
  yield takeLatest(
    fetchSetupEngagementManagerSelectionCreatorTypeName,
    fetchSetupEngagementManagerSelectionWorker,
  );
}

/**
 * fetch managerSelectionData Worker
 */
export function* fetchSetupEngagementManagerSelectionWorker(action: any): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.roleId !== 'undefined' &&
      typeof action.payload.divisions !== 'undefined'
    ) {
      const url = `${API_PATH.setupEngagement.managerSelectionData}/${action.payload.roleId}?divisions=${action.payload.divisions}`;
      const managerSelectionData = yield call(NetworkService.get, url, {}, {});
      let managerSelectionList: any = [];
      if (managerSelectionData?.data.length > 0) {
        managerSelectionList = managerSelectionData?.data.map((i: any) => {
          return {
            ...i,
            name: i.fullName,
          };
        });
      }
      if (managerSelectionData.status === 200) {
        yield put(setupEngagementModuleStateActions.setLoaderData({isLoading: false}))
        yield put(
          setupEngagementModuleStateActions.setManagerSelectionData([
            ...managerSelectionList,
          ]),
        );
      } else {
        yield put(setupEngagementModuleStateActions.setLoaderData({isLoading: false}))
        showEngagementErrors(
          managerSelectionData?.data?.error || 'Failed to load data',
        );
      }
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put(setupEngagementModuleStateActions.setLoaderData({isLoading: false}))
    console.error(error);
  }
}

/**
 * fetch lastYearHighlightsData Watcher
 */
export function* fetchLastYearHighlightsWatcher() {
  yield takeLatest(
    fetchLastYearHighlightsDataCreatorTypeName,
    fetchLastYearHighlightsWatcherWorker,
  );
}

/**
 * fetch lastYearHighlightsData Worker
 */
export function* fetchLastYearHighlightsWatcherWorker(action: any): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.url !== 'undefined'
    ) {
      if (
        action.payload.url === 'constraints' &&
        typeof action.payload.divisionIds !== 'undefined' &&
        typeof action.payload.fromDate !== 'undefined' &&
        typeof action.payload.toDate !== 'undefined'
      ) {
        const url = `${API_PATH.setupEngagement.lastYearHighlights}/${action.payload.url}?divisions=${action.payload.divisionIds}&fromDate=${action.payload.fromDate}&toDate=${action.payload.toDate}`;
        const lastYearHighlightsData = yield call(
          NetworkService.get,
          url,
          {},
          {},
        );
        const newLastYearHighlightsData: any =
          lastYearHighlightsData?.data.filter(
            (item: any) => item.constraintsDesc !== '',
          );
        if (lastYearHighlightsData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          yield put(
            setupEngagementModuleStateActions.setLastYearHighlightsData({
              lastYearHighlightsConstraintsData: newLastYearHighlightsData,
            }),
          );
        } else {
          showEngagementErrors(
            lastYearHighlightsData?.data?.error || 'Failed to load data',
          );
        }
      } else if (
        action.payload.url === 'managerlist' &&
        typeof action.payload.divisionId !== 'undefined'
      ) {
        const postEngagementData = yield select(
          setupEngagementModuleStateSelector.getPostEngagementData(),
        );
        const url = `${API_PATH.setupEngagement.lastYearHighlights}/${action.payload.url}/${action.payload.divisionId}`;
        const stateHeadList = yield call(NetworkService.get, url, {}, {});
        if (stateHeadList.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setLastYearHighlightsData({
              regionwiseStateHeadList: stateHeadList?.data,
            }),
          );
        } else {
          showEngagementErrors(
            stateHeadList?.data?.error || 'Failed to load data',
          );
        }
      } else {
        throw new Error();
      }
    }
  } catch (error) {
    yield put(
      setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
    );
    console.error(error);
  }
}

/**
 * post eligibleDoctors by constraints data Watcher
 */
export function* postEligibleDoctorsByConstraintsDataWatcher() {
  yield takeLatest(
    postEligibleDoctorsByConstraintsDataCreatorTypeName,
    postEligibleDoctorsByConstraintsDataWorker,
  );
}

/**
 * post eligibleDoctors by constraints data Worker
 */
export function* postEligibleDoctorsByConstraintsDataWorker(action: any): any {
  const eligibleDoctorsConstraintsData = yield select(
    setupEngagementModuleStateSelector.getEligibleDoctorsConstraintsData(),
  );
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.url !== 'undefined'
    ) {
      if (action.payload.url === 'eligibledoctorscount') {
        if(
          eligibleDoctorsConstraintsData.engagementconstraintqualifications.length ===0 &&
          eligibleDoctorsConstraintsData.engagementconstraintpractices.length ===0 &&
          eligibleDoctorsConstraintsData.engagementconstraintsdoccategories.length ===0
          ) {
            yield put(
              setupEngagementModuleStateActions.setResponseData({
                numberOfEligibleDoctors: 0,
              }),
            );
          } else {
            const url = `${API_PATH.setupEngagement.doctorsByConstraints}/${action.payload.url}`;
            const numberOfEligibleDoctors = yield call(
              NetworkService.post,
              url,
              eligibleDoctorsConstraintsData,
            );
            yield put(
              setupEngagementModuleStateActions.setResponseData({
                numberOfEligibleDoctors: numberOfEligibleDoctors,
              }),
            );
          }
      } else if (action.payload.url === 'eligibledoctorslist') {
        const url = `${API_PATH.setupEngagement.doctorsByConstraints}/${action.payload.url}`;
        const listOfEligibleDoctors = yield call(
          NetworkService.post,
          url,
          eligibleDoctorsConstraintsData,
        );
        yield put(
          setupEngagementModuleStateActions.setResponseData({
            listOfEligibleDoctors: listOfEligibleDoctors,
          }),
        );
      }

      //last year highlights doctors list api
      else if (action.payload.url === 'lastyearhighlights/doctorlist') {
        if (
          typeof action.payload.fromDate !== 'undefined' &&
          typeof action.payload.toDate !== 'undefined'
        ) {
          const userInfo = yield select(appSelector.getUserInfo());
          let divisionIds = userInfo?.division?.map((i: any) => {
            return i.id;
          });
          const url = `${API_PATH.setupEngagement.doctorsByConstraints}/${
            action.payload.url
          }?divisions=${divisionIds.join(',')}&fromDate=${
            action.payload.fromDate
          }&toDate=${action.payload.toDate}`;
          const doctorsList = yield call(NetworkService.get, url, {}, {});
          if (doctorsList.status === 200) {
            yield put(
              setupEngagementModuleStateActions.setLastYearHighlightsData({
                lastYearHighlightsDoctorsList: doctorsList?.data,
                doctorsListData: doctorsList?.data,
              }),
            );
            yield put(
              setupEngagementModuleStateActions.setLoaderData({
                isLoading: false,
              }),
            );
          } else {
            yield put(
              setupEngagementModuleStateActions.setLoaderData({
                isLoading: false,
              }),
            );
            showEngagementErrors(
              doctorsList?.data?.error || 'Failed to load data',
            );
          }
        }
      }

      //last year highlights managers list api
      else if (action.payload.url === 'lastyearhighlights/managerlist') {
        if (
          typeof action.payload.divisionId !== 'undefined' &&
          typeof action.payload.staffPositionId !== 'undefined'
        ) {
          const url = `${API_PATH.setupEngagement.doctorsByConstraints}/${action.payload.url}/${action.payload.divisionId}/${action.payload.staffPositionId}`;
          const managersList = yield call(NetworkService.get, url, {}, {});
          if (managersList.status === 200) {
            yield put(
              setupEngagementModuleStateActions.setLoaderData({
                isLoading: false,
              }),
            );
            yield put(
              setupEngagementModuleStateActions.setLastYearHighlightsData({
                lastYearHighlightsManagersList: managersList?.data,
              }),
            );
          } else {
            yield put(
              setupEngagementModuleStateActions.setLoaderData({
                isLoading: false,
              }),
            );
            yield put(
              setupEngagementModuleStateActions.setLastYearHighlightsData({
                lastYearHighlightsManagersList: [],
              }),
            );
            showEngagementErrors(
              managersList?.data?.error || 'Failed to load Response Data',
            );
          }
        }
      } else {
        yield put(
          setupEngagementModuleStateActions.setLoaderData({
            isLoading: false,
          }),
        );
        throw new Error();
      }
    }
  } catch (error) {
    yield put(
      setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
    );
    console.error(error);
  }
}

/**
 * post setupEngagementData Watcher
 */
export function* postSetupEngagementDataWatcher() {
  yield takeLatest(
    postSetupEngagementDataCreatorTypeName,
    postSetupEngagementDataWatcherWorker,
  );
}

/**
 * post setupEngagementData Worker
 */
export function* postSetupEngagementDataWatcherWorker(action: any): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.url !== 'undefined'
    ) {
      if (action.payload.url === 'setup') {
        const postBasicInformationData = yield select(
          setupEngagementModuleStateSelector.getPostBasicInformationData(),
        );
        const url = `${API_PATH.setupEngagement.postEngagementData}/${action.payload.url}`;
        const basicInfoData = yield call(
          NetworkService.post,
          url,
          postBasicInformationData,
        );
        if (basicInfoData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              basicInfoResponseData: basicInfoData,
            }),
          );
        } else {
          showEngagementErrors(
            basicInfoData?.data?.error || 'Failed to load data',
          );
        }
      } else if (action.payload.url === 'focusbrands') {
        const postFocusBrandsData = yield select(
          setupEngagementModuleStateSelector.getPostFocusBrandsData(),
        );
        const url = `${API_PATH.setupEngagement.postEngagementData}/${action.payload.url}`;
        const focusbrandsData = yield call(
          NetworkService.post,
          url,
          postFocusBrandsData,
        );
        if (focusbrandsData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              focusBrandsResponseData: focusbrandsData,
            }),
          );
        } else {
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              focusBrandsResponseData: {},
            }),
          );
          showEngagementErrors(
            focusbrandsData?.data?.error || 'Failed to load data',
          );
        }
      } else if (action.payload.url === 'coverages') {
        const postCoveragesData = yield select(
          setupEngagementModuleStateSelector.getPostCoverageData(),
        );
        const url = `${API_PATH.setupEngagement.postEngagementData}/${action.payload.url}`;
        const coveragesData = yield call(
          NetworkService.post,
          url,
          postCoveragesData,
        );
        if (coveragesData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              coveragesResponseData: coveragesData,
            }),
          );
        } else {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              coveragesResponseData: {},
            }),
          );
          showEngagementErrors(
            coveragesData?.data?.error || 'Failed to load data',
          );
        }
      } else if (action.payload.url === 'constraints') {
        const postConstraintsData = yield select(
          setupEngagementModuleStateSelector.getPostConstraintsData(),
        );
        const url = `${API_PATH.setupEngagement.postEngagementData}/${action.payload.url}`;
        const constraintsData = yield call(
          NetworkService.post,
          url,
          postConstraintsData,
        );
        if (constraintsData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              constraintsResponseData: constraintsData,
            }),
          );
        } else {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              constraintsResponseData: {},
            }),
          );
          showEngagementErrors(
            constraintsData?.data?.error || 'Failed to load data',
          );
        }
      } else if (action.payload.url === 'enrollments') {
        const postEnrollmentsData = yield select(
          setupEngagementModuleStateSelector.getPostEnrollmentConfigsData(),
        );
        const postEngagementData = yield select(
          setupEngagementModuleStateSelector.getPostEngagementData(),
        );
        let updatedPostEnrollmentData = postEnrollmentsData.map(
          (item: any) => ({
            ...item,
            isHard: postEngagementData?.assignDoctorsToggleValue,
          }),
        );
        const url = `${API_PATH.setupEngagement.postEngagementData}/${action.payload.url}`;
        const enrollmentsData = yield call(
          NetworkService.post,
          url,
          updatedPostEnrollmentData,
        );
        if (enrollmentsData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              enrollmentConfigResponseData: enrollmentsData,
            }),
          );
        } else {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              enrollmentConfigResponseData: {},
            }),
          );
          showEngagementErrors(
            enrollmentsData?.data?.error || 'Failed to load data',
          );
        }
      } else if (action.payload.url === 'responsibilities' && action.payload.id) {
        const postResponsibilitiesData = yield select(
          setupEngagementModuleStateSelector.getPostResponsibilitiesData(),
        );
        const url = `${API_PATH.setupEngagement.postEngagementData}/${action.payload.url}?engagementId=${action.payload.id}`;
        const responsibilitiesData = yield call(
          NetworkService.post,
          url,
          postResponsibilitiesData,
        );
        if (responsibilitiesData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              assignResponsibilitiesResponseData: responsibilitiesData,
            }),
          );
        } else {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              assignResponsibilitiesResponseData: {},
            }),
          );
          showEngagementErrors(
            responsibilitiesData?.data?.error || 'Failed to load data',
          );
        }
      } else if (action.payload.url === 'files/upload') {
        const inCompleteEngagementInfo = yield select(
          setupEngagementModuleStateSelector.getInCompleteEngagementInfo()
        )
        let postData: any;
        if (
          typeof action.payload.type !== 'undefined' &&
          action.payload.type === 'literature'
        ) {
          const data = yield select(
            setupEngagementModuleStateSelector.getPostTemplateData(),
          );
          postData = data?.postLiteratureData;
        } else {
          const invitationFile = yield select(
            setupEngagementModuleStateSelector.getPostTemplateData(),
          );
          postData = invitationFile?.postInvitationData;
        }
        const url = `${API_PATH.setupEngagement.postEngagementData}/${action.payload.url}`;
        const templateData = yield call(NetworkService.post, url, postData);
        if (templateData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              fileUploadResponseData: templateData,
            }),
          );
          yield put(
            setupEngagementModuleStateActions.setPostBasicInformationData({
              setupDone: true,
              Id: action.payload.id,
              status: "Enroll Doctors ",
            }),
          );
          yield put(postSetupEngagementDataCreator({url: 'updateengagement'}));
        } else {
          showEngagementErrors(
            templateData?.data?.error || 'Failed to load data',
          );
        }
      } else if (action.payload.url === 'doctors') {
        const postDoctorsData = yield select(
          setupEngagementModuleStateSelector.getSelectedDoctorsData(),
        );
        const url = `${API_PATH.setupEngagement.postEngagementData}/${action.payload.url}`;
        const doctorsData = yield call(
          NetworkService.post,
          url,
          postDoctorsData,
        );
        if (doctorsData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setResponseData({
              doctorsData: doctorsData,
            }),
          );
        } else {
          yield put(
            setupEngagementModuleStateActions.setResponseData({
              doctorsData: {},
            }),
          );
          showEngagementErrors(
            doctorsData?.data?.error || 'Failed to load data',
          );
        }
      } else if (action.payload.url === 'updateengagement') {
        const postBasicInformationData = yield select(
          setupEngagementModuleStateSelector.getPostBasicInformationData(),
        );
        const inCompleteEngagementInfo = yield select(
          setupEngagementModuleStateSelector.getInCompleteEngagementInfo()
        )
        const postEngagementData = yield select (
          setupEngagementModuleStateSelector.getPostEngagementData()
        )
        const url = `${API_PATH.setupEngagement.postEngagementData}/${action.payload.url}?isEdit=${postEngagementData?.engagementIsEdit}`;
        const updatedEngagementResponse = yield call(
          NetworkService.post,
          url,
          postBasicInformationData,
        );
        if (updatedEngagementResponse.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          if(action.payload.type === 'incompleteEngagementData' && !inCompleteEngagementInfo?.setupDone){            
            yield put(
              setupEngagementModuleStateActions.setPostResponseData({
                updatedEngagementResponse: updatedEngagementResponse,
              }),
            );
            yield put(
              setupEngagementModuleStateActions.setPostEngagementData({
                setupIsValid: {
                  isValid: false,
                  message: ''
                }
              }),
            );
          }else if(action.payload.type === 'incompleteEngagementData' && inCompleteEngagementInfo?.setupDone){            
            yield put(
              setupEngagementModuleStateActions.setPostResponseData({
                updatedEngagementResponse: updatedEngagementResponse,
              }),
            );
            yield put(
              setupEngagementModuleStateActions.setPostEngagementData({
                setupIsValid: {
                  isValid: true,
                  message: ''
                }
              }),
            );
          }
          else{
            yield put(
              setupEngagementModuleStateActions.setResponseData({
                updatedEngagementResponse: updatedEngagementResponse,
              }),
            );
          yield put(
            setupEngagementModuleStateActions.setPostEngagementData({
              setupIsValid: {
                isValid: true,
                message: 'Done'
              }
            }),
          );
        }
        } else {
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          yield put(
            setupEngagementModuleStateActions.setPostEngagementData({
              setupIsValid: {
                isValid: false,
                message: ''
              }
            }),
          );
          showEngagementErrors(
            updatedEngagementResponse?.data?.error || 'Failed to load data',
          );
        }
      } else {
        throw new Error();
      }
    }
  } catch (error) {
    yield put(
      setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
    );
    console.error(error);
  }
}

/**
 * delete coverageSelection data Watcher
 */
export function* removeCoverageSelectionDataWatcher() {
  yield takeLatest(
    removeCoverageSelectionDataCreatorTypeName,
    removeCoverageSelectionDataWatcherWorker,
  );
}

/**
 * delete coverageSelection data Worker
 */
export function* removeCoverageSelectionDataWatcherWorker(action: any): any {
  const removeCoverageSelectionData = yield select(
    setupEngagementModuleStateSelector.getRemoveCoverageSelectionData(),
  );
  try {
    const url = `${API_PATH.setupEngagement.removeCoverageSelection}`;
    const coverageData = yield call(
      NetworkService.Delete,
      url,
      removeCoverageSelectionData,
    );
    if (coverageData.status === 200) {
      yield put(
        setupEngagementModuleStateActions.setResponseData({
          removeCoverageSelectionResponse: coverageData,
        }),
      );
    } else {
      showEngagementErrors(coverageData?.data?.error || 'Failed to load data');
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * fetch inCompleteEngagementsData Watcher
 */
export function* inCompleteEngagementsDataWatcher() {
  yield takeLatest(
    inCompleteEngagementsDataCreatorTypeName,
    inCompleteEngagementsDataWatcherWorker,
  );
}

/**
 * fetch inCompleteEngagementsData Worker
 */
export function* inCompleteEngagementsDataWatcherWorker(action: any): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.url !== 'undefined'
    ) {
      if (action.payload.url === 'noncompletedengagements')
      {
        const url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}`;
        const inCompleteEngagementsData = yield call(
          NetworkService.get,
          url,
          {},
          {},
        );
        if (inCompleteEngagementsData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          yield put(
            setupEngagementModuleStateActions.setInCompleteEngagementsData(inCompleteEngagementsData?.data),
          );
        } else {
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          showEngagementErrors(
            inCompleteEngagementsData?.data?.error || 'Failed to load data',
          );
        }
      } 

      else if (
        action.payload.url === 'noncompletedengagements/engagementinfo' &&
        typeof action.payload.engagementId !== 'undefined'
        ) {
        const url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}/${action.payload.engagementId}`;
        const inCompleteEngagementInfo = yield call(
          NetworkService.get,
          url,
          {},
          {},
        );
        if (inCompleteEngagementInfo.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setInCompleteEngagementInfo(inCompleteEngagementInfo?.data),
          );
          yield put(inCompleteEngagementsConstraintsDataCreator({url: 'noncompletedengagements/engagementinfo/constraints', engagementId: action.payload.engagementId}))
        } else {
          showEngagementErrors(
            inCompleteEngagementInfo?.data?.error || 'Failed to load data'
          );
        }
      } 

      else if (action.payload.url === 'focusdivisions') {
        const postDivisionsData = yield select(
          setupEngagementModuleStateSelector.getPostDivisionsData(),
        );
        const url = `${API_PATH.setupEngagement.postEngagementData}/${action.payload.url}`;
        const divisionsData = yield call(
          NetworkService.post,
          url,
          postDivisionsData,
        );
        if (divisionsData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              divisionsResponseData: divisionsData,
            }),
          );
        } else {
          yield put(
            setupEngagementModuleStateActions.setPostResponseData({
              divisionsResponseData: {},
            }),
          );
          showEngagementErrors(
            divisionsData?.data?.error || 'Failed to load data',
          );
        }
      }

      else if (
        action.payload.url === 'completedengagements/search' &&
        typeof action.payload.search !== 'undefined' &&
        typeof action.payload.status !== 'undefined'
        ) {
        const url = `${API_PATH.setupEngagement.postEngagementData}/${action.payload.url}?search=${action.payload.search}&status=${action.payload.status}`;
        const filteredData = yield call(
          NetworkService.get,
          url,
          {},
          {}
        );
        if (filteredData.status === 200) {
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          if(action.payload.engType === 'completed') {
            yield put(
              setupEngagementModuleStateActions.setCompletedEngagementsData(filteredData?.data),
            );
          } else {
            yield put(
              setupEngagementModuleStateActions.setInProgressEngagementsData(filteredData?.data),
            );
          }
        } else {
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          showEngagementErrors(
            filteredData?.data?.error || 'Failed to load data',
          );
          if(action.payload.engType === 'completed') {
            yield put(
              setupEngagementModuleStateActions.setCompletedEngagementsData([]),
            );
          } else {
            yield put(
              setupEngagementModuleStateActions.setInProgressEngagementsData([]),
            );
          }
        }
      }
      else {
        throw new Error();
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* completedEngagementsDataWatcher() {
  yield takeLatest(
    completedEngagementsDataCreatorTypeName,
    completedEngagementsDataWatcherWorker,
  );
}

/**
 * fetch completedEngagementsData Worker
 */
export function* completedEngagementsDataWatcherWorker(action:any): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.url !== 'undefined'
    ) {
      if (action.payload.url === 'completedengagements' && action.payload.engType === 'completed')
      {
        let url:any = ''
        const engagementInfo = yield select(
          setupEngagementModuleStateSelector.getCompletedEngagementsFilterData())
        const completedEngagements = yield select(
          setupEngagementModuleStateSelector.getCompletedEngagementsData())
        let engagementsData:any = []

        if(action.payload.type === 'clear') {
          engagementsData = []
        } else {
          engagementsData = [...completedEngagements]
        }

        if(engagementInfo?.year && engagementInfo?.search) {
          url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}?pageNo=${engagementInfo?.pageNo}&pageSize=${engagementInfo?.pageSize}&year=${engagementInfo?.year}&status=Completed&search=${engagementInfo?.search}`;
        } else if(engagementInfo?.year && !engagementInfo?.search) {
          url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}?pageNo=${engagementInfo?.pageNo}&pageSize=${engagementInfo?.pageSize}&year=${engagementInfo?.year}&status=Completed`;
        } else if(!engagementInfo?.year && engagementInfo?.search) {
          url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}?pageNo=${engagementInfo?.pageNo}&pageSize=${engagementInfo?.pageSize}&status=Completed&search=${engagementInfo?.search}`;
        } else {
          url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}?pageNo=${engagementInfo?.pageNo}&pageSize=${engagementInfo?.pageSize}&status=Completed`;
        }

        const completedEngagementsData = yield call(
          NetworkService.get,
          url,
          {},
          {},
        );
        if (completedEngagementsData.status === 200) {
          if(completedEngagementsData?.data?.length === 10){
            yield put(setupEngagementModuleStateActions.setPostEngagementData({loadMoreIsEnable: true}))
          } else {
            yield put(setupEngagementModuleStateActions.setPostEngagementData({loadMoreIsEnable: false}))
          }

          engagementsData.push(...completedEngagementsData?.data)
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          yield put(
            setupEngagementModuleStateActions.setCompletedEngagementsData(engagementsData),
          );
        } else {
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          yield put(
            setupEngagementModuleStateActions.setCompletedEngagementsData([]),
          );
          showEngagementErrors(
            completedEngagementsData?.data?.error || 'Failed to load data',
          );
        }
      } 
      else {
        throw new Error();
      }
    }
  } catch (error) {
    console.error(error);
}
}

export function* inProgressEngagementsDataWatcher() {
  yield takeLatest(
    inProgressEngagementsDataCreatorTypeName,
    inProgressEngagementsDataWatcherWorker,
  );
}

export function* inProgressEngagementsDataWatcherWorker(action:any): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.url !== 'undefined'
    ) {
      if (action.payload.url === 'completedengagements' && action.payload.engType === 'inProgress')
      {
        let url:any = ''
        const inProgressInfo = yield select(
          setupEngagementModuleStateSelector.getInProgressEngagementsFilterData())
        const inProgressEngagements = yield select(
          setupEngagementModuleStateSelector.getInProgressEngagementsData())
        let inProgressEngagementsData:any = []

        if(action.payload.type === 'clear') {
          inProgressEngagementsData = []
        } else {
          inProgressEngagementsData = [...inProgressEngagements]
        }

        if(inProgressInfo?.year) {
          url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}?pageNo=${inProgressInfo?.pageNo}&pageSize=${inProgressInfo?.pageSize}&year=${inProgressInfo?.year}`;
        } else {
          url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}?pageNo=${inProgressInfo?.pageNo}&pageSize=${inProgressInfo?.pageSize}`;
        }

        if(inProgressInfo?.year && inProgressInfo?.search) {
          url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}?pageNo=${inProgressInfo?.pageNo}&pageSize=${inProgressInfo?.pageSize}&year=${inProgressInfo?.year}&search=${inProgressInfo?.search}`;
        } else if(inProgressInfo?.year && !inProgressInfo?.search) {
          url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}?pageNo=${inProgressInfo?.pageNo}&pageSize=${inProgressInfo?.pageSize}&year=${inProgressInfo?.year}`;
        } else if(!inProgressInfo?.year && inProgressInfo?.search) {
          url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}?pageNo=${inProgressInfo?.pageNo}&pageSize=${inProgressInfo?.pageSize}&search=${inProgressInfo?.search}`;
        } else {
          url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}?pageNo=${inProgressInfo?.pageNo}&pageSize=${inProgressInfo?.pageSize}`;
        }

        const inProgressCompletedEngagementsData = yield call(
          NetworkService.get,
          url,
          {},
          {},
        );
        if (inProgressCompletedEngagementsData.status === 200) {
          if(inProgressCompletedEngagementsData?.data?.length === 10){
            yield put(setupEngagementModuleStateActions.setPostEngagementData({inProgressLoadMore: true}))
          } else {
            yield put(setupEngagementModuleStateActions.setPostEngagementData({inProgressLoadMore: false}))
          }

          inProgressEngagementsData.push(...inProgressCompletedEngagementsData?.data)
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          yield put(
            setupEngagementModuleStateActions.setInProgressEngagementsData(inProgressEngagementsData),
          );
        } else {
          yield put(
            setupEngagementModuleStateActions.setLoaderData({isLoading: false}),
          );
          yield put(
            setupEngagementModuleStateActions.setInProgressEngagementsData([]),
          );
          showEngagementErrors(
            inProgressCompletedEngagementsData?.data?.error || 'Failed to load data',
          );
        }
      } 
      else {
        throw new Error();
      }
    }
  } catch (error) {
    console.error(error);
}
}

/**
 * fetch inCompleteEngagementsConstraintsData Watcher
 */
export function* inCompleteEngagementsConstraintsDataWatcher() {
  yield takeLatest(
    inCompleteEngagementsConstraintsDataCreatorTypeName,
    inCompleteEngagementsConstraintsDataWatcherWorker,
  );
}

/**
 * fetch inCompleteEngagementsConstraintsData Worker
 */
export function* inCompleteEngagementsConstraintsDataWatcherWorker(action: any): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.url !== 'undefined'
    ) {
     if (
        action.payload.url === 'noncompletedengagements/engagementinfo/constraints' &&
        typeof action.payload.engagementId !== 'undefined'
        ) {
        const url = `${API_PATH.setupEngagement.engagements}/${action.payload.url}/${action.payload.engagementId}`;
        const constraintsData = yield call(
          NetworkService.get,
          url,
          {},
          {},
        );
        if (constraintsData.status === 200) {
           yield put(
            setupEngagementModuleStateActions.setEngagementInfoLoaderData({engagementInfoIsLoading: false}),
          );
          yield put(
            setupEngagementModuleStateActions.setInCompleteEngagementConstraintsData(constraintsData?.data),
          );
        } else {
          yield put(
            setupEngagementModuleStateActions.setEngagementInfoLoaderData({engagementInfoIsLoading: false}),
          );
          showEngagementErrors(
            constraintsData?.data?.error || 'Failed to load data'
          );
        }
      } 
      else {
        throw new Error();
      }
    }
  } catch (error) {
    yield put(
      setupEngagementModuleStateActions.setEngagementInfoLoaderData({engagementInfoIsLoading: false}),
    );
    console.error(error);
  }
}



