import Typography from '@mui/material/Typography';
import {useSelector, shallowEqual} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {Box} from '@mui/material';
import backIcon from '@app/assets/images/icon_48_move.png';
import '@app/screens/campaign-management/pages/setup-engagement-module/setup-engagement-screens/assign.css';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

const styleSheet = () => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  };
};

export const ShowListNavBar = () => {
  const styles = styleSheet();
  const navigate=useNavigate()

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
    shallowEqual,
  );
  const navbarComponentSubHeading = useSelector(
    routeSelector.getNavbarComponentSubHeading(),
    shallowEqual,
  );

  function onClickHandler() {
    navigate('/approval')
  }

  const onKeyDownHandler = (e: any) => {
    e.preventDefault();
  }

  return (
    <Box sx={styles.container}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <img
          src={backIcon}
          className="icon"
          onClick={onClickHandler}
          style={{cursor: 'pointer', width: '32px', height: '32px'}}
          role='button'
          onKeyDown={onKeyDownHandler}
          alt='backIcon'
        />
        <Typography variant="h6" noWrap component="div">
          {navbarComponentHeading}
        </Typography>
      </Stack>
      <Typography>
        {navbarComponentSubHeading}
      </Typography>
    </Box>
  );
};
