import {Box} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {setupEngagementModuleStateSelector} from '@app/screens/campaign-management/pages/setup-engagement-module/redux/selectors';
import { approvalStateActions } from '@app/screens/campaign-management/pages/approval/redux';
import { ApprovalClickableChips } from '@app/screens/campaign-management/components/campaign-chips/approvalCampaignChips';
import { useEffect, useState } from 'react';
import { approvalStateSelector } from '@app/screens/campaign-management/pages/approval/redux/selectors';

export const ApprovalChips = () => {
  const dispatch = useDispatch()
  const [engagementTypes, setEngagementTypes] = useState([])

  const engagementData = useSelector(
    setupEngagementModuleStateSelector.getEngagementData(),
  );

  const approvalData = useSelector(
    approvalStateSelector.getApprovalData()
  )

  const data: any = {};

  engagementData?.forEach((item: any) => {
    item.dropdownName === 'EngagementTypes' &&
      (data.engagementTypes = item.dropdownValues);
  });

  function selectedItems(value: any) {
    dispatch(approvalStateActions.setApprovalData({approvalEngagementType: value}))
  }

  useEffect(() => {
    if(data?.engagementTypes) {
      let engagementType = data?.engagementTypes?.filter((item:any) => item.label === 'Campaign')
      setEngagementTypes(engagementType)
      dispatch(approvalStateActions.setApprovalData({approvalEngagementType: engagementType}))
    }
  },[data?.engagementTypes])

  return (
    <Box>
      <ApprovalClickableChips
        data={engagementTypes}
        isShowScroll={true}
        selectedItems={selectedItems}
        title=""
        onSelectData={approvalData?.approvalEngagementType}
      />
    </Box>
  );
};
