import {createSelector} from '@reduxjs/toolkit';

export const setupEngagementModuleStateSelector = {
  getEngagementData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.engagementData],
      engagementData => engagementData,
    );
  },
  getDivisionData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.divisionsData],
      divisionsData => divisionsData,
    );
  },
  getLoaderData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.loaderData],
      loaderData => loaderData,
    );
  },
  getEngagementInfoLoaderData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.engagementInfoLoaderData],
      engagementInfoLoaderData => engagementInfoLoaderData,
    );
  },
  getFocusBrandsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.focusBrandsData],
      focusBrandsData => focusBrandsData,
    );
  },
  getFilteredFocusBrandsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.filteredFocusBrandsData],
      filteredFocusBrandsData => filteredFocusBrandsData,
    );
  },
  getStateSelectionData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.stateSelectionData],
      stateSelectionData => stateSelectionData,
    );
  },
  getManagerSelectionData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.managerSelectionData],
      managerSelectionData => managerSelectionData,
    );
  },
  getResponsibilitiesData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.responsibilitiesData],
      responsibilitiesData => responsibilitiesData,
    );
  },
  getAssignDoctorsMrData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.assignDoctorsMrData],
      assignDoctorsMrData => assignDoctorsMrData,
    );
  },
  getAssignManagersMrData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.assignManagersMrData],
      assignManagersMrData => assignManagersMrData,
    );
  },
  getDoctorsMrData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.doctorsMrData],
      doctorsMrData => doctorsMrData,
    );
  },
  getManagersMrData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.managersMrData],
      managersMrData => managersMrData,
    );
  },
  getSelectedDoctorsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.selectedDoctorsData],
      selectedDoctorsData => selectedDoctorsData,
    );
  },
  getPostEngagementData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.postEngagementData],
      postEngagementData => postEngagementData,
    );
  },
  getPostBasicInformationData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.postBasicInformationData],
      postBasicInformationData => postBasicInformationData,
    );
  },
  getPostDivisionsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.postDivisionsData],
      postDivisionsData => postDivisionsData,
    );
  },
  getPostFocusBrandsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.postFocusBrandsData],
      postFocusBrandsData => postFocusBrandsData,
    );
  },
  getPostCoverageData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.postCoverageData],
      postCoverageData => postCoverageData,
    );
  },
  getPostConstraintsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.postConstraintsData],
      postConstraintsData => postConstraintsData,
    );
  },
  getPostEnrollmentConfigsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.postEnrollmentConfigsData],
      postEnrollmentConfigsData => postEnrollmentConfigsData,
    );
  },
  getPostResponsibilitiesData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.postResponsibilitiesData],
      postResponsibilitiesData => postResponsibilitiesData,
    );
  },
  getPostResponseData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.postResponseData],
      postResponseData => postResponseData,
    );
  },
  getResponseData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.responseData],
      responseData => responseData,
    );
  },
  getPostTemplateData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.postTemplateData],
      postTemplateData => postTemplateData,
    );
  },
  getEligibleDoctorsConstraintsData: () => {
    return createSelector(
      [
        (state: any) =>
          state.setupEngagementModule.eligibleDoctorsConstraintsData,
      ],
      eligibleDoctorsConstraintsData => eligibleDoctorsConstraintsData,
    );
  },
  getRemoveCoverageSelectionData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.removeCoverageSelectionData],
      removeCoverageSelectionData => removeCoverageSelectionData,
    );
  },
  getLastYearHighlightsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.lastYearHighlightsData],
      lastYearHighlightsData => lastYearHighlightsData,
    );
  },
  getManagersListParameters: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.managersListParameters],
      managersListParameters => managersListParameters,
    );
  },
  getSegmentationData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.segmentationData],
      segmentationData => segmentationData,
    );
  },
  getInCompleteEngagementsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.inCompleteEngagementsData],
      inCompleteEngagementsData => inCompleteEngagementsData,
    );
  },
  getCompletedEngagementsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.completedEngagementsData],
      completedEngagementsData => completedEngagementsData,
    );
  },
  getInProgressEngagementsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.inProgressEngagementsData],
      inProgressEngagementsData => inProgressEngagementsData,
    );
  },
  getCompletedEngagementsFilterData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.completedEngagementsFilterData],
      completedEngagementsFilterData => completedEngagementsFilterData,
    );
  },
  getInProgressEngagementsFilterData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.inProgressEngagementsFilterData],
      inProgressEngagementsFilterData => inProgressEngagementsFilterData,
    );
  },
  getInCompleteEngagementInfo: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.inCompleteEngagementInfo],
      inCompleteEngagementInfo => inCompleteEngagementInfo,
    );
  },
  getInCompleteEngagementConstraintsData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.inCompleteEngagementConstraintsData],
      inCompleteEngagementConstraintsData => inCompleteEngagementConstraintsData,
    );
  },
  getRejectionDropdownData: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.rejectionDropdownData],
      rejectionDropdownData => rejectionDropdownData,
    );
  },
  getBase64Data: () => {
    return createSelector(
      [(state: any) => state.setupEngagementModule.base64Data],
      base64Data => base64Data,
    );
  },
};
