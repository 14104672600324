import {Box, Chip, Stack, Typography, useTheme} from '@mui/material';
import {useState, useCallback, useEffect, useRef} from 'react';
import {useStyles} from './styles';
import moveRight from '@app/assets/images/svgs/move_right.svg';
import moveLeft from '@app/assets/images/svgs/move_left.svg';
import Colors from '@app/themes/colors';

type propsData = {
  data: any;
  selectedItems: any;
  title: string;
  isShowScroll: boolean;
  onSelectData?: any;
};
export const ApprovalClickableChips = (props: propsData) => {
  const classes = useStyles();
  const theme = useTheme();
  let campaignApprovalChipRef = useRef<any>(null);
  const {
    data,
    isShowScroll,
    selectedItems,
    onSelectData,
    title
  } = props;
  const [scrollX, setScrollX] = useState<any>(0);
  const [scrollEnd, setScrollEnd] = useState<any>(false);
  const [overflowActive, setOverflowActive] = useState(false);

  const isSelected = (item: any) => {
    let index: any
    if (onSelectData) {
      index = onSelectData.findIndex((i: any) =>
        (item.value != undefined && i.value === item.value)
      )
    } else {
      index = -1
    }
    return (index >= 0)
  }

  const handleClick = useCallback(
    (e: any) => {
      const rowItem = JSON.parse(e.currentTarget.dataset.rawdata);
      if(!isSelected(rowItem)) {
        const newItems = [];
        newItems.push(rowItem);

        selectedItems(newItems);
      } else {
        let items = onSelectData.filter((i:any) => i.value !== rowItem.value);
        selectedItems(items);
      }
    },
    [onSelectData],
  );

  const slideApprovalCampaignList = (value: number) => {
    if (campaignApprovalChipRef) {
      campaignApprovalChipRef.current.scrollLeft += value;
      setScrollX(scrollX + value);
    }

    return Math.floor(
      campaignApprovalChipRef.current.scrollWidth - campaignApprovalChipRef.current.scrollLeft,
    ) <= campaignApprovalChipRef.current?.offsetWidth
      ? setScrollEnd(true)
      : setScrollEnd(false);
  };
  const scrollApprovalCampaignCheckList = useCallback(() => {
    setScrollX(campaignApprovalChipRef.current.scrollLeft);
    return Math.floor(
      campaignApprovalChipRef.current.scrollWidth - campaignApprovalChipRef.current.scrollLeft,
    ) <= campaignApprovalChipRef.current.offsetWidth
      ? setScrollEnd(true)
      : setScrollEnd(false);
  }, []);

  function isOverflowApprovalScrollActive(event: any) {
    return (
      event.offsetHeight < event.scrollHeight ||
      event.offsetWidth < event.scrollWidth
    );
  }

  useEffect(() => {
    if (isOverflowApprovalScrollActive(campaignApprovalChipRef.current)) {
      setOverflowActive(true);
      return;
    }
    setOverflowActive(false);
  }, [isOverflowApprovalScrollActive]);

  const onKeyDown = (e: any) => {
    e.preventDefault();
  }

  return (
    <Box>
      <Typography variant="h6" sx={{mb: 2}} className={classes.chipTitle}>
        {title}
      </Typography>
      <Box className={`${classes.makeScroll} hide-scrollbar`}>
        <Box className={classes.scrollDiv}>
          {isShowScroll && scrollX !== 0 && (
            <input type='image'
              src={moveLeft}
              style={{marginBottom: '15px', cursor: 'pointer'}}
              className={classes.scrollRightIcon}
              width={32}
              alt="scroll left"
              onClick={() => slideApprovalCampaignList(-500)}
              onKeyDown={onKeyDown}
            />
          )}

          <Stack
            direction="row"
            spacing={2}
            data-testid='approvalCampaign_stack'
            className={`${classes.makeScroll} hide-scrollbar`}
            marginBottom={'15px'}
            sx={{listStyle: 'none'}}
            ref={campaignApprovalChipRef}
            onScroll={scrollApprovalCampaignCheckList}>
            {data?.map((item: any) => {
              return (
                <Chip
                key={item.value}
                sx={{
                  padding: '0px 12px',
                  [theme.breakpoints.between('sm', 'lg')]: {
                    padding: '0px 6px',
                  },
                  '& .MuiChip-label': {
                    padding: '0px 2px',
                  },
                }}
                onClick={handleClick}
                data-rawData={JSON.stringify({...item})}
                label={item.label}
                clickable={true}
                className={classes.campaignChips}
                data-testid='approvalCampaign_chip'
                style={{
                    fontWeight:
                    isSelected(item)
                        ? 1000
                        : 400,
                    backgroundColor:
                    isSelected(item)
                        ? `${Colors.blue[400]}`
                        : '#f6f6f7',
                  }}
                />
              );
            })}
          </Stack>

          {isShowScroll && !scrollEnd && (
            <input type='image'
              src={moveRight}
              style={{
                marginBottom: '15px',
                cursor: 'pointer',
                display: overflowActive ? 'block' : 'none',
              }}
              className={classes.scrollRightIcon}
              width={32}
              alt="scroll right"
              onClick={() => slideApprovalCampaignList(+500)}
              onKeyDown={onKeyDown}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
