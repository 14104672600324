import {makeStyles} from '@mui/styles';
import theme from '@app/themes';
import Colors from '@app/themes/colors';
import {fontFamilies} from '@app/themes/typography';

export const useStyles = makeStyles({
  container: {marginTop: '20px'},
  overflowY: {
    overflowY: 'scroll',
  },
  campaignChips: {
    fontSize: '14px',
    color: theme.colors.LIGHT_NAVY_BLUE,
    fontStyle: 'normal',
    textAlign: 'center',
    fontFamily: fontFamilies.fontRegular,
    fontWeight: 400,
    '@media (min-width: 600px) and (max-width: 899px)': {
      fontSize: '12.7px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      fontSize: '13px',
    },
  },
  chipTitle: {
    color: Colors.grey[200],
    fontSize: '16px',
    fontFamily: fontFamilies.fontSemiBold,
    '@media (min-width: 600px) and (max-width: 899px)': {
      fontSize: '14px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      fontSize: '15px',
    },
  },
  paperContainer: {
    overflow: 'auto',
    marginTop: '20px',
  },
  scrollDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  makeScroll: {
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
  },

  scrollRightIcon: {
    width: '32px',
    '@media (min-width: 600px) and (max-width: 899px)': {
      width: '24px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      width: '28px',
    },
  },
});
