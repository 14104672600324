import { createAction, createSlice } from '@reduxjs/toolkit';

export const approvalInitialState = {
    showListData: [],
    loaderData: {
        isLoading: false,
        mssg: ''
    },
    approvalToggleState: '',
    approvalData: {
        categoryData: [],
        specialityData: [],
        showListData: [],
        showListResponse: [],
        engagementStatusData: [],
        approvalHistoryData: [],
        approvalHistoryPayloadData: {},
        engagementsList: [],
        showListPayloadData: {},
        approvalEngagementType: '',
        wsfaData:[],
        isApproved:false,
    },
    approvalStatusData: {
        engagementUser: {},
        engagementPartyApprovalList: []
    },
    approvalStatusResponseData: {}, 
    approvalCustomerListResponseData: {}, 
}

export const fetchApprovalDataCreator = createAction<any>(
    'APPROVAL_MODULE/FETCH_APPROVAL_DATA',
);
export const fetchApprovalDataCreatorTypeName =
    fetchApprovalDataCreator(null).type;

export const fetchApprovalCustomerListDataCreator = createAction<any>(
    'APPROVAL_MODULE/FETCH_APPROVAL_CUSTOMER_LIST_DATA',
);
export const fetchApprovalCustomerListDataCreatorTypeName =
    fetchApprovalCustomerListDataCreator(null).type;

const approvalStateHandler = createSlice({
    name: 'APPROVAL_HANDLER',
    initialState: approvalInitialState,
    reducers: {
        setShowListData: (prevState, action) => {
            const { payload } = action;
            return {
                ...prevState,
                showListData: payload,
            };
        },
        setLoaderData: (prevState, action) => {
            const { payload } = action;
            return {
                ...prevState,
                loaderData: payload,
            };
        },
        setApprovalData: (prevState, action) => {
            const { payload } = action;
            return {
                ...prevState,
                approvalData: { ...prevState.approvalData, ...payload },
            };
        },
        setApprovalStatusData: (prevState, action) => {
            const { payload } = action;
            return {
                ...prevState,
                approvalStatusData: {...prevState.approvalStatusData, ...payload},
            };
        },
        setApprovalStatusResponseData: (prevState, action) => {
            const { payload } = action;
            return {
                ...prevState,
                approvalStatusResponseData: payload,
            };
        },
        setApprovalCustomerListResponseData: (prevState, action) => {
            const { payload } = action;
            return {
                ...prevState,
                approvalCustomerListResponseData: payload,
            };
        },
        setApprovalToggleState: (prevState, action) => {
            const { payload } = action;
            return {
                ...prevState,
                approvalToggleState: payload,
            };
        },
        setApprovalwsfaCodes: (prevState, action) => {
            const { payload } = action;
            return {
                ...prevState,
                approvalwsfaCodes: payload,
            };
        },
    }
})

export const approvalStateActions =
    approvalStateHandler.actions;
export const approvalStateReducer =
    approvalStateHandler.reducer;